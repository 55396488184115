import ModulosTableComponente from "../components/modulos/modulos.componentes";

const Modulos = () => {
  return (
    <div className="d-flex justify-content-start alig-items-center flex-column h-100 mx-2">
      <div>
        <ModulosTableComponente />
      </div>
    </div>
  );
};

export default Modulos;
