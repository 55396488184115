import Axios from "../config/Axios";

export const allVeterinarias = async () => {
  //allpersonsData()

  try {
    const respuesta = await Axios.get("/allVeteterinarias");
    console.log(respuesta.data)
      return respuesta.data
    
  } catch (e) {
    return { sms: "err", mensaje: e, data: [] };
  }
};


