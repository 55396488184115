import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaLock, FaMoneyBill, FaUser } from "react-icons/fa";
import { ReactComponent as HomeIcon } from "../../svg/home.svg";
import { ReactComponent as CogIcon } from "../../svg/cog.svg";

import { ReactComponent as AdminIcon } from "../../svg/admin.svg";
import { ReactComponent as MedicIcon } from "../../svg/medic.svg";
import { ReactComponent as ArrowIcon } from "../../svg/arrow.svg";
import AuthContext from "../../Context/auth/authContext";
import { rolverifyTodoPanel } from "../../utils/verificaroles";

//import { ReactComponent as HomeIcon } from "../../svg/home.svg";

import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    icon: <HomeIcon width="100%" />,
  },
  {
    path: "/modulos",
    name: "Módulos",
    icon: <MedicIcon width="100%" />,
  },
  {
    path: "/veterinarias",
    name: "Veterinarias",
    icon: <AdminIcon />,
  },
  {
    path: "/configuraciones",
    name: "Configuraciones",
    icon: <CogIcon />,
  },

  /* {
    path: "/settings",
    name: "Settings",
    icon: <BiCog />,
    exact: true,
    subRoutes: [
      {
        path: "/settings/profile2",
        name: "Profile",
        icon: <FaUser />,
      },
      {
        path: "/settings/2fa",
        name: "2FA",
        icon: <FaLock />,
      },
      {
        path: "/settings/billing",
        name: "Billing",
        icon: <FaMoneyBill />,
      }, 
    ],
  },*/
];

const SideBar = ({ children }) => {
  const AuthenticationContext = useContext(AuthContext);
  const { sessiondatacmm, setearSesiondataFn, roles } = AuthenticationContext;

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const salir = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location = "/";
  };

  const crearMenu = (route, index) => {
    if (route.subRoutes) {
      return (
        <SidebarMenu
          key={index}
          setIsOpen={setIsOpen}
          route={route}
          showAnimation={showAnimation}
          isOpen={isOpen}
        />
      );
    }

    return (
      <NavLink
        to={route.path}
        key={index}
        className="link"
        activeClassName="active"
      >
        <div className="icon">{route.icon}</div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              variants={showAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
              className="link_text"
            >
              {route.name}
            </motion.div>
          )}
        </AnimatePresence>
      </NavLink>
    );
  };

  return (
    <>
      <div className="main-container bgfondo">
        <motion.div
          animate={{
            width: isOpen ? "250px" : "56px",

            transition: {
              duration: 0.5,
              //type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar borde-tarjeta`}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  MENU
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars icon">
              <FaBars onClick={toggle} />
            </div>
          </div>

          <section className="routes" style={{ padding: "8px" }}>
            {routes.map((route, index) => {
             
                return crearMenu(route, index);
            
            })}
            <NavLink
              to={"/"}
              className="link"
              activeClassName="active"
              onClick={(e) => salir(e)}
            >
              <div className="icon">
                <ArrowIcon></ArrowIcon>
              </div>
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="link_text"
                  >
                    Salir
                  </motion.div>
                )}
              </AnimatePresence>
            </NavLink>
          </section>
        </motion.div>
        <main
          className="d-flex flex-column w-100 justify-content-center align-items-center align-self-center "
          style={{ overflow: "auto", height: "100vh" }}
        >
          {/* <div className="w-100">
            xxx
          </div> */}
          <div className="w-100 h-100 ">{children}</div>
        </main>
      </div>
    </>
  );
};

export default SideBar;
