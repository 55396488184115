import React, { useEffect, useContext } from "react";
import "./App.css";
import SideBar from "./components/Sidebar/SideBar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Splash from "./pages/Splash";
import Login from "./pages/login/Login";
import AuthContext from "./Context/auth/authContext";
import Dashboard from "./pages/Dashboard"
import Modulos from "./pages/Modulos";

import Veterinarias from "./pages/veterinarias"
import VeterinariasDetalles from "./components/veterinarias/veterinarias.detalles.componente";
import VeterinariasCrear from "./components/veterinarias/veterinarias.crear.componentes";


import { toast } from "react-toastify";
import { authenticationDataFn } from "./api/auth";
import { getIpLocation } from "./api/ipLocation";
const App = () => {
  const AuthenticationContext = useContext(AuthContext);
  const { sessiondataadm, setearSesiondataFn, roles } = AuthenticationContext;

  useEffect(() => {
    if (localStorage.getItem("accesTokenAdm")) {
      inicializar();
    }
  }, []);

  const inicializar = async () => {
    const locale = await getIpLocation();
    console.log(locale);
    if (locale.length > 0) {
      if (locale[0].languages === "es-EC") {
        require("moment/locale/es");
      }/*  else {
        localStorage.clear();
        window.location = "/";
      } */
    }
    const res = await authenticationDataFn();
    console.log(res);
    switch (res[0].sms) {
      case "ok":
        setearSesiondataFn(res[0].result);
        break;
      default:
        toast.error(`${res[0].mensaje}`);
        localStorage.clear();
        window.location = "/";
        break;
    }
  };

  return (
    <Router>
      {localStorage.getItem("accesTokenAdm") ? (
        sessiondataadm.length === 0 ? (
          <Splash />
        ) : (
          <SideBar>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/veterinarias" element={<Veterinarias />} />
              <Route
                path="/veterinarias/detalles/:veterinaria_id"
                element={<VeterinariasDetalles />}
              />
              <Route
                path="/veterinarias/crear"
                element={<VeterinariasCrear />}
              />

              <Route path="/modulos" element={<Modulos />} />

              <Route path="*" element={<> not found</>} />
            </Routes>{" "}
          </SideBar>
        )
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<>No es posible acceder</>} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
