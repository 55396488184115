import React, { useEffect, useState } from "react";
import ReactDataTable from "../ReactDataTable";
import { allVeterinarias } from "../../api/veterinarias";
import { ReactComponent as RolesIcon } from "../../svg/roles.svg";
import ModalDefault from "../Modal/modalDefault";
import { FormGroup, Label, Col, Input } from "reactstrap";
import { filtrarUsuario } from "../../utils/filtrosUsuariosTable";
import { DataTable } from "mantine-datatable";
import moment from "moment";

import { useNavigate } from "react-router-dom";
const VeterinariaTableComponente = (props) => {
  const navigate = useNavigate();

  const [filtro, setFiltro] = useState("");
  const [state, setState] = useState([]);
  const [stateAuxiliar, setStateAuxiliar] = useState([]);
  const [modalRoles, setModalRoles] = useState(false);
  const [modalClave, setModalClave] = useState(false);
  const [menu, setMenu] = useState("menu-oculto");

  const [selectData, setSelectData] = useState({});

  const PAGE_SIZE = 10;
  const [page, setPage] = useState(1);
  const [data, setdata] = useState([]);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(data.slice(from, to));
  }, [page]);

  const columns = [
    {
      name: "CODIGO",
      selector: (row) => row.codigo,
      sortable: false,
    },
    {
      name: "NOMBRES",
      selector: (row) => row.nombre,
      sortable: false,
    },
    {
      name: "TELEFONO",
      selector: (row) => row.telefono,
      sortable: false,
    },

    {
      name: "OPCIONES",
      sortable: false,
      cell: (row) => opciones(row),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

  const opciones = (row) => {
    return (
      <div id="menuT">
        <button
          type=""
          className="btn btn-primary btn-sm m-1"
          onClick={() => {
            navigate("/veterinarias/detalles/" + btoa(row.veterinaria_id));
          }}
        >
          Módulos
        </button>
      </div>
    );
  };

  /*  window.addEventListener("click", function (e) {
    if (document.getElementById("menuT").contains(e.target)) {
    } else {
         const boxes = document.querySelectorAll(".showmenutable");
         boxes.forEach((box) => {
           // ✅ Remove class from each element
           box.classList.remove("showmenutable");

           // ✅ Add class to each element
           // box.classList.add('small');
         });
        
    
    }
  });
 */
  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    const res = await allVeterinarias();
    if (res.sms === "ok") {
      const data2 = JSON.parse(res.data);
          console.log("===================");
      console.log(data2)
      setState(data2);
      setdata(data2);
      setStateAuxiliar(data2);
      setRecords(data2.slice(0, PAGE_SIZE));
    }
  };

  const closeActionModal = (data, bandera) => {
    if (bandera === "clave") {
      setModalClave(data);
      setSelectData({});
    }
    if (bandera === "roles") {
      setModalRoles(data);
      setSelectData({});
    }
  };

  const onchangefilter = (e) => {
    setFiltro(e.target.value);
    if (e.target.value === "") {
      setStateAuxiliar(state);
    } else {
      const res = filtrarUsuario(state, e.target.value);
      setStateAuxiliar(res);
    }
  };

  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-5">
          <FormGroup>
            <Label for="Nombres" sm={12}>
              Buscar:
            </Label>
            <Col sm={12}>
              <Input
                type="text"
                value={filtro}
                id="filtrar"
                name="filtrar"
                placeholder="Filtrar"
                onChange={onchangefilter}
              />
            </Col>
          </FormGroup>{" "}
        </div>
        <div className="w-100">
          <DataTable
            withBorder
            highlightOnHover
            minHeight={150}
            noRecordsText="No se encontraron datos"
            height="auto"
            records={records}
            columns={[
              {
                accessor: "nombre",
                title: "EMPRESA",
                render: ({ nombre }) => nombre,

                textAlignment: "left",
              },
              {
                accessor: "plan",
                title: "PLAN CONTRATADO",
                render: ({ empresas_planes }) =>
                  empresas_planes.length > 0
                    ? empresas_planes[0].planes.plan
                    : "No se ah definido un plan",

                textAlignment: "center",
              },

              {
                accessor: "facturacion_electronica",
                textAlignment: "center",
                //width: 120,
                render: ({ facturacion_electronica }) =>
                  facturacion_electronica ? "ACTIVO" : "INACTIVO",
              },
              {
                accessor: "estado",
                textAlignment: "center",
                //width: 120,
                render: ({ estado }) => (estado ? "ACTIVO" : "INACTIVO"),
              },
              {
                accessor: "create_at",
                tittle: "F/CREACIÓN",
                textAlignment: "center",
                //width: 120,
                render: ({ create_at }) =>
                  moment(create_at).fromNow(),
              },
              {
                accessor: "acciones",
                title: "Acciones",
                textAlignment: "center",
                render: (record) => (
                  <div>
                    <button
                      type=""
                      onClick={() => {
                        setState({
                          ...state,
                          bien: record.bien,
                          bien_id: record.bien_id,
                          editar: true,
                        });
                      }}
                    >
                      Editar
                    </button>
                    <button type="" onClick={() => {}}>
                      Eliminar
                    </button>
                  </div>
                ),
              },
            ]}
            totalRecords={data.length}
            recordsPerPage={PAGE_SIZE}
            page={page}
            onPageChange={(p) => setPage(p)}
            withColumnBorders
            rowContextMenu={{
              items: (record) => [
                // ...
                {
                  key: "delete",
                  color: "red",

                  title: `Delete company ${record.name}`,
                  onClick: () => {
                    alert(`${record.bien_id}`);
                  },
                },
                {
                  key: "edit",
                  color: "red",

                  title: `Delete company ${record.name}`,
                  onClick: () => {
                    alert(`${record.bien_id}`);
                  },
                },

                // ...
              ],
            }}
          />
        </div>

        <ModalDefault
          size="md"
          modal={modalRoles}
          bandera="roles"
          closeAction={closeActionModal}
          titulo="Roles de usuario"
          icontitulo={<RolesIcon width={30} className="mx-2 " fill="blue" />}
        ></ModalDefault>
        <ModalDefault
          size="md"
          modal={modalClave}
          bandera="clave"
          closeAction={closeActionModal}
          titulo="Clave de usuario"
          icontitulo={<RolesIcon width={30} className="mx-2 " fill="blue" />}
        ></ModalDefault>
      </div>
    </div>
  );
};

export default VeterinariaTableComponente;
