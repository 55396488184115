import load from "../images/load2.gif"

const Splash =()=>{
    return (
        <div className="d-flex justify-content-center align-items-center " style={{height:"100vh"}}>
            <img src={load} alt="" style={{width:"50%",height:"50%"}}/>
              
        </div> 
    )
}

export default Splash