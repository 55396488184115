import React ,{useState,useEffect} from 'react';

const Modulo =({editar})=>{

const [state,setState]=useState({
    modulo:"",
    link:"",
    route:"",
    icon:""
})
  return (
    <div>
      <form>
        <div className="card-body">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group row">
                  <div className="col-md-2 d-flex d-flex justify-content-start align-items-center">
                    <label className="" htmlFor="exampleInputEmail1">
                      Módulo :
                    </label>
                  </div>
                  <div className="col-md-10">
                    <input
                      type="text"
                      className="inputtext form-control"
                      onChange={onchange}
                      value={state.modulo}
                      id="modulo"
                      name="modulo"
                      placeholder="Ingrese el nombre del módulo"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group row">
                  <div className="col-md-2 d-flex d-flex justify-content-start align-items-center">
                    {" "}
                    <label className="" htmlFor="exampleInputEmail1">
                      Link :
                    </label>
                  </div>
                  <div className="col-md-10">
                    {" "}
                    <input
                      type="text"
                      className="inputtext form-control"
                      onChange={onchange}
                      value={state.link}
                      id="link"
                      name="link"
                      placeholder="Ingrese el link  "
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group row">
                  <div className="col-md-2 d-flex d-flex justify-content-start align-items-center">
                    {" "}
                    <label className="" htmlFor="exampleInputEmail1">
                      Route
                    </label>
                  </div>
                  <div className="col-md-10">
                    <input
                      type="text"
                      className="inputtext form-control"
                      onChange={onchange}
                      value={state.route}
                      id="route"
                      name="route"
                      placeholder="Ingrese el route "
                    />
                    <span>El route se usara para generar las rutas </span>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className="row ">
              {editar ? null : (
                <button
                  type="button"
                  className="btn btn-primary p-3 "
                  style={{ float: "right" }}
                  onClick={(e) => {}}
                >
                  Guardar
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Modulo