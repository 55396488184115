import Axios from "../config/Axios";
//import moment from 'moment';

export const getPaisesFn = async () => {
  // let start = moment().format("YYYY-MM-DD HH:mm:ss");
  try {
    const respuesta = await Axios.get(`/loadPaises`);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const getProvinciasFn = async (pais_id) => {
  try {
    const respuesta = await Axios.get(`/loadProvincias/${pais_id}`);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const getCiudadesFn = async (provincia_id) => {
  try {
    const respuesta = await Axios.get(`/loadCiudades/${provincia_id}`);
    return [
     
      respuesta.data
   
    ];
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensae:e,
      },
    ];
  }
};
