import Axios from "../config/Axios";
import TokenAuth from "../config/Token";

export const getTipoEmpresas = async () => {
  try {
    const respuesta = await Axios.get("/getTipoEmpresas");
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};