import React, { useContext, useEffect, useRef, useState } from "react";

import {
  soloPermitirTextoConEspacio,
  soloPermitirNumeros,
  soloPermitirTextoConEspacioCaracteresBasicos,
} from "../../config/validaciones";
import {
  getProvinciasFn,
  getPaisesFn,
} from "../../api/ubicaciones";
import { allModulos } from "../../api/modulos";
import Axios from "../../config/Axios";
import { BuscarUsers } from "../../api/usuarios";
//import Swal from 'sweetalert2'

import {getTipoEmpresas} from "../../api/tipo_empresas"

//CREAR VETERINARIA//
const CrearVeterinaria = (props) => {
  const[tipoempresas,setTipoEmpresas]=useState([])
  const [paises, setPaises] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [state, setState] = useState({
    nombre: "",
    ruc: "",
    direccion: "",
    usuario_id: "",
    ciudad: "",
    provincia_id: "",
    pais_id: "",
    telefono: "",
    type:"",
    correo: "",
    modulos: [],
  });
  const [stateReset, setStateReset] = useState({
    nombre: "",
    ruc: "",
    type:"",
    direccion: "",
    ciudad: "",
    provincia_id: "",
    pais_id: "",
    usuario_id: "",
    telefono: "",
    correo: "",
    modulos: [],
  });

  const [usuario, setUsuario] = useState(null);
  const [search, setSearch] = useState("");
  const [modulos, setModulos] = useState([]);
  const [modulosSelect, setModulosSelect] = useState("");

  const [statefire, setStatefire] = useState({
    mensaje: "Iniciando registro ...",
    info: "info",
  });

  const [stateModal, setStateModal] = useState({
    configModal: false,
  });

  useEffect(() => {
    init();
    obtenertipoempresas()
  }, []);

  const obtenertipoempresas=async()=>{
   const res = await getTipoEmpresas()
   setTipoEmpresas(res[0].data)
  }

  const selectModuloRef = useRef(null);

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    let _modulos = [...state.modulos];
    const draggeItemContento = _modulos.splice(dragItem.current, 1)[0];
    _modulos.splice(dragOverItem.current, 0, draggeItemContento);
    dragItem.current = null;
    dragOverItem.current = null;
    _modulos.map((data, index) => {
      data.orden = index + 1;
    });
    setState({
      ...state,
      modulos: _modulos,
    });
  };

  const ondragstart = (e, index) => {
    console.log(index);
  };

  const ondragenter = (e, index) => {
    console.log(index);
  };

  const ondragsend = (e, index) => {
    console.log(index);
  };

  const init = async () => {
    const res = await getPaisesFn();
    if (res[0].sms === "ok") {
      setPaises(res[0].paises);
    }
  };

  const onchangeModulo = (e) => {
    e.preventDefault();
    setModulosSelect(e.target.value);
  };

  const onchange = (e, valor) => {
    e.preventDefault();

    if (e.target.name === "search") {
      setSearch(e.target.value);
      return;
    }

    if (e.target.value === "") {
      setState({
        ...state,
        [e.target.name]: null,
      });
    } else if (e.target.name === "nombre") {
      //inicio if principal campo nombre ====================================================
      if (soloPermitirTextoConEspacio.test(e.target.value)) {
        //inicio if para comprobar que el valor ingresado solo contenga texto y espacios
        //** */
        if (
          e.target.value[
            e.target.value.length === 0
              ? e.target.value.length
              : e.target.value.length - 1
          ] === " "
        ) {
          if (
            state.nombre[
              state.nombre.length === 0
                ? state.nombre.length
                : state.nombre.length - 1
            ] !== " "
          ) {
            setState({
              ...state,
              [e.target.name]: e.target.value.toUpperCase(),
            });
          }
        } else {
          setState({
            ...state,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } //** */ //finn if para comprobar que el valor ingresado solo contenga texto y espacios

      //inicio elseif principal campo apellidos ====================================================
    } else if (e.target.name === "ruc") {
      if (soloPermitirNumeros.test(e.target.value)) {
        setState({
          ...state,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "direccion") {
      if (
        e.target.value[
          e.target.value.length === 0
            ? e.target.value.length
            : e.target.value.length - 1
        ] === " "
      ) {
        if (
          state.direccion[
            state.direccion.length === 0
              ? state.direccion.length
              : state.direccion.length - 1
          ] !== " "
        ) {
          setState({
            ...state,
            [e.target.name]: e.target.value.toUpperCase(),
          });
        }
      } else {
        setState({
          ...state,
          [e.target.name]: e.target.value.toUpperCase(),
        });
      }

      //fin if principal otros compos  ====================================================
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "pais_id") {
      obtenerProvincias(e);
      return;
    }
  };

  const obtenerProvincias = async (e) => {
    e.preventDefault();
    const res = await getProvinciasFn(e.target.value);
    console.log(res);
    if (res[0].sms === "ok") {
      setProvincias(res[0].provincias);
    }
  };

  const CrearVeterinaria = async (e) => {
    e.preventDefault();
    if (state.nombre === "") {
      alert("El nombre es obligatoria");
    } else if (state.ruc === "" || state.ruc.length != 13) {
      alert("El RUC es oblgatorio y debe contener 13 caracteres");
    } else if (state.direccion === "") {
      alert("La dirección es obligatoria");
    } else if (state.provincia_id === null || state.provincia_id === "") {
      alert("Debe seleccionar la provincia");
    } else if (state.ciudad === null || state.ciudad === "") {
      alert("Se debe ingresar la ciudad");
    } else {
      setStateModal({
        ...stateModal,
        configModal: true,
      });
      try {
        const respuesta = await Axios.post("/createVeterinaria", state);
        if (respuesta.data.sms === "ok") {
          setStatefire({
            ...statefire,
            mensaje: `${respuesta.data.mensaje}`,
          });
          setState({
            ...state,
            nombre: "",
            ruc: "",
            direccion: "",
            ciudad: "",
            provincia_id: "",
          });
        } else {
          setStatefire({
            ...statefire,
            mensaje: `${respuesta.data.mensaje}`,
          });
        }
      } catch (e) {}
    }
  };

  const BuscarUsersFn = async (e) => {
    if (e.target.value === "") {
    } else if (e.key === "Enter") {
      const respuesta = await BuscarUsers(e.target.value);
      if (respuesta[0].sms === "ok") {
        setUsuario(respuesta[0].result[0]);
        setState({
          ...state,
          usuario_id: respuesta[0].result[0].usuario_id,
        });
        const resModulos = await allModulos();
        setModulos(resModulos[0].data);
      } else {
        alert(respuesta[0].mensaje)
        setUsuario(null);
      }
    }
  };

  const agregarModule = (e) => {
    e.preventDefault();
    if (state.modulos.length > 0) {
      let res = state.modulos.filter((elemento) => elemento.modulo_id===modulosSelect);
      if(res.length>0){
        alert("El modulo ya se encuentra en la selección")
      }else{
        let aux= state.modulos
        let obj ={
      
            modulo_id: modulosSelect,
            orden: state.modulos.length+1,
            modulo:
              selectModuloRef.current.options[
                selectModuloRef.current.selectedIndex
              ].text,
        }
        aux.push(obj)

        setState({
          ...state,
          modulos:aux
        })
      }
        
    } else {
      setState({
        ...state,
        modulos: [
          {
            modulo_id: modulosSelect,
            orden: 1,
            modulo:
              selectModuloRef.current.options[
                selectModuloRef.current.selectedIndex
              ].text,
          },
        ],
      });
    }
  };

  return (
    <>
      {/* Main content */}
      <section className="row">
        <div className="col-md-12">
          <div className="w-100 py-2">
            <label for="" className="titulosubmenusize">
              Registro de empresa
            </label>
          </div>
          <div className="w-100 bordecircularpanel contenidopanel bgfondodark p-2">
            <div className="col-md-12">
              {usuario ? (
                <div className="row  ">
                  <div>
                    Usuario principal:{" "}
                    {usuario.nombres + " " + usuario.apellidos}
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setUsuario(null);
                        setState(stateReset);
                      }}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="form-group row">
                    <label htmlFor="exampleInputEmail1">
                      Filtrar cliente por número de cédula:
                    </label>
                    <br></br>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Filtre la cédula del cliente y precione enter"
                      aria-label="codigobuscar"
                      aria-describedby="addon-wrapping"
                      onChange={onchange}
                      onKeyUp={(e) => BuscarUsersFn(e)}
                      id="search"
                      name="search"
                      value={search}
                    />
                    <span className="sizeInfoInput">
                      Filtre el usuario que será el principal de la empresa.
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-12">
              {usuario ? (
                modulos.length > 0 ? (
                  <div className="row">
                    <div className="col-md-8 ">
                      <label>Tipo de empresa :</label>
                      <select
                        className="form-control select2bs4"
                        id="type"
                        name="type"
                        style={{ width: "100%" }}
                        onChange={onchange}
                        value={state.type}
                        ref={null}
                      >
                        <option value="">Seleccione ...</option>
                        {tipoempresas.map((data, index) => {
                          return (
                            <option value={data.tipo} key={index}>
                              {data.tipo}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                   
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center flex-column row">
                    Aun no tenemos los tipo de empresas
                  </div>
                )
              ) : null}
            </div>
            <div className="col-md-12">
              {usuario ? (
                modulos.length > 0 ? (
                  <div className="row">
                    <div className="col-md-8 ">
                      <label>Módulo :</label>
                      <select
                        className="form-control select2bs4"
                        id="modulosSelect"
                        name="modulosSelect"
                        style={{ width: "100%" }}
                        onChange={onchangeModulo}
                        value={modulosSelect}
                        ref={selectModuloRef}
                      >
                        <option value="">Seleccione ...</option>
                        {modulos.map((data, index) => {
                          return (
                            <option value={data.modulo_id} key={index}>
                              {data.modulo}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-4 ">
                      <button
                        className="btn btn-primary"
                        onClick={(e) => agregarModule(e)}
                      >
                        Agregar
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center flex-column row">
                    Aun no tenemos modulos creados
                  </div>
                )
              ) : null}
            </div>

            {state.modulos.length > 0 ? (
              <div className=" col-md-12">
                <div className="row">Listado de módulos que se agregarán</div>
                {state.modulos.map((data, index) => {
                  return (
                    <div
                      draggable
                      className="grabbable p-2 my-2  d-flex justify-content-start align-items-center row"
                      style={{
                        border: "1px solid #115400",
                        background: "#115400",
                        color: "white",
                      }}
                      value={data.modulo_id}
                      key={index}
                      onDragStart={(e) => (dragItem.current = index)}
                      onDragEnter={(e) => (dragOverItem.current = index)}
                      onDragEnd={(e) => {
                        handleDrag(e);
                      }}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <div
                        style={{
                          width: "50px",
                          background: "grey",
                          textAlign: "center",
                        }}
                      >
                        #{data.orden}
                      </div>
                      <div className="mx-2">Módulo {data.modulo}</div>
                    </div>
                  );
                })}
              </div>
            ) : null}

            <hr className="my-5"></hr>

            {state.modulos.length > 0 && usuario ? (
              <div className="col-md-12">
                {/* general form elements */}
                <div className="card card-primary elevation-2">
                  {/* general form elements */}

                  <form role="form">
                    <div className="card-body">
                      <div className="col-md-12">
                        <div
                          className="row mb-9"
                          style={{ borderBottom: "1px solid #d8d8d8" }}
                        >
                          <p style={{ fontSize: "16px" }}>
                            {" "}
                            INFORMACIÓN DE EMPRESA{" "}
                          </p>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Nombre* :
                              </label>
                              <br></br>
                              <input
                                type="text"
                                className="form-control mayuscula"
                                onChange={onchange}
                                value={state.nombre}
                                id="nombre"
                                name="nombre"
                                placeholder="Ingrese nombre de la empresa"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Ruc* :</label>
                              <br></br>
                              <input
                                type="text"
                                className="form-control mayuscula"
                                onChange={onchange}
                                value={state.ruc}
                                id="ruc"
                                name="ruc"
                                placeholder="Ingrese RUC"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Correo :
                              </label>
                              <br></br>
                              <input
                                type="text"
                                className="form-control mayuscula"
                                onChange={onchange}
                                value={state.correo}
                                id="correo"
                                name="correo"
                                placeholder="Ingrese correo"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Direccion* :
                              </label>
                              <br></br>
                              <input
                                type="text"
                                className="form-control"
                                onChange={onchange}
                                value={state.codigo}
                                id="direccion"
                                name="direccion"
                                placeholder="Ingrese la dirección"
                              />
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Pais* :</label>
                              <select
                                className="form-control select2bs4"
                                id="pais_id"
                                name="pais_id"
                                style={{ width: "100%" }}
                                onChange={(e) => onchange(e, 1)}
                                value={state.pais_id}
                              >
                                <option value="">Seleccione ...</option>
                                {paises.map((data, index) => (
                                  <option value={data.pais_id} key={index}>
                                    {data.pais}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Provincia * :</label>
                              <select
                                className="form-control select2bs4"
                                id="provincia_id"
                                name="provincia_id"
                                style={{ width: "100%" }}
                                onChange={(e) => onchange(e)}
                                value={state.provincia_id}
                              >
                                <option value="">Seleccione ...</option>
                                {provincias.map((data, index) => (
                                  <option value={data.provincia_id} key={index}>
                                    {data.provincia}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Ciudad* :
                              </label>
                              <br></br>
                              <input
                                type="text"
                                className="form-control"
                                onChange={onchange}
                                value={state.ciudad}
                                id="ciudad"
                                name="ciudad"
                                placeholder="Digite la ciudad"
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            {/*<div className="form-group">
                    <label htmlFor="exampleInputEmail1">Ext:</label>
                    <label htmlFor="exampleInputEmail1">{ext}</label>
                          </div>*/}

                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">
                                Telefono * :
                              </label>
                              <br></br>
                              <input
                                type="text"
                                className="form-control"
                                onChange={onchange}
                                value={state.telefono}
                                id="telefono"
                                name="telefono"
                                placeholder="Ingrese el telefono "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* /.card-body */}
                    <div className="card-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ float: "right" }}
                        onClick={(e) => CrearVeterinaria(e)}
                      >
                        Guardar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
            {/*/.col (left) */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </>
  );
};

export default CrearVeterinaria;
