import Axios from "../config/Axios";
import TokenAuth from "../config/Token";

export const allModulos = async () => {
  //allpersonsData()

  try {
    const respuesta = await Axios.get("/getModulos");
    //console.log(respuesta.data)
    if (respuesta.data.sms === "ok") {
      return [respuesta.data];
    } else {
    }
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};

//FUNCION PARA  OBTEER LOS MODULOS ASIGNADOS A LAS EMPRESAS
export const allModulosDeVeterinarias = async (veterinaria_id) => {

  try {
    const respuesta = await Axios.get(
      "/getModulosEmpresaCentral/" + atob(veterinaria_id)
    );
    console.log(respuesta.data)
    if (respuesta.data.sms === "ok") {
      return [respuesta.data];
    } else {
    }
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};



//FUNCION PARA  ASIGNAR  LOS MODULOS SEGUN LOS ROLES QUE NO SEAN ALL ACCESS
export const createModuleRolAccess = async (state) => {

  try {
    const respuesta = await Axios.post(
      "/createModulosRolAccess", {modulos:state}
    );
    console.log(respuesta.data)
    if (respuesta.data.sms === "ok") {
      return [respuesta.data];
    } else {
    }
  } catch (e) {
    return [{ sms: "err", mensaje: e, data: [] }];
  }
};


