import React, { useEffect, useState } from "react";
import ReactDataTable from "../ReactDataTable";
import { allModulos } from "../../api/modulos";
import { ReactComponent as RolesIcon } from "../../svg/roles.svg";
import ModalDefault from "../Modal/modalDefault";
import { FormGroup, Label, Col, Input } from "reactstrap";
import { filtrarUsuario } from "../../utils/filtrosUsuariosTable";
import ReactTooltip from "react-tooltip";
import ModulosCrud from "./registro.componentes"
const ModulosTableComponente = (props) => {
  const [filtro, setFiltro] = useState("");
  const [state, setState] = useState([]);
  const [stateAuxiliar, setStateAuxiliar] = useState([]);
  const [modalRoles, setModalRoles] = useState(false);
  const [modalClave, setModalClave] = useState(false);
  const [menu, setMenu] = useState("menu-oculto");

  const [selectData, setSelectData] = useState({});


  const [modalModuloCrud,setModalModuloCrud]=useState(false)

  const columns = [
    {
      name: "MODULO",
      selector: (row) => row.modulo,
      sortable: false,
      center: true,
    },
    {
      name: "LINK",
      selector: (row) => row.link,
      sortable: false,
      center: true,
    },
    {
      name: "ROUTE",
      selector: (row) => row.route,
      sortable: false,
      center: true,
    },
    {
      name: "ICON",
      selector: (row) => row.icon,
      sortable: false,
      center: true,
    },
    {
      name: "ACTIVO",
      selector: (row) => row.activo,
      cell: (row) => (row.activo ? "true" : "false"),
      center: true,
      sortable: false,
    },
    {
      name: "OPCIONES",
      sortable: false,
      center: true,

      cell: (row) => opciones(row),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];



  const opciones = (row) => {
    return (
      <div id="menuT">
        <button type="" className="btn btn-primary btn-sm m-1">
         Editar
        </button>

      </div>
    );
  };

  /*  window.addEventListener("click", function (e) {
    if (document.getElementById("menuT").contains(e.target)) {
    } else {
         const boxes = document.querySelectorAll(".showmenutable");
         boxes.forEach((box) => {
           // ✅ Remove class from each element
           box.classList.remove("showmenutable");

           // ✅ Add class to each element
           // box.classList.add('small');
         });
        
    
    }
  });
 */
  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    const res = await allModulos();
    console.log(res);

    setState(res[0].data);
    setStateAuxiliar(res[0].data);
  };

  const closeActionModal = (data, bandera) => {
    if (bandera === "clave") {
      setModalClave(data);
      setSelectData({});
    }
    if (bandera === "roles") {
      setModalRoles(data);
      setSelectData({});
    }
  };

  const onchangefilter = (e) => {
    setFiltro(e.target.value);
    if (e.target.value === "") {
      setStateAuxiliar(state);
    } else {
      const res = filtrarUsuario(state, e.target.value);
      setStateAuxiliar(res);
    }
  };

  return (
    <div className="">
      <div className="col-md-5">
        {/*   <FormGroup>
          <Label for="Nombres" sm={12}>
            Buscar:
          </Label>
          <Col sm={12}>
            <Input
              type="text"
              value={filtro}
              id="filtrar"
              name="filtrar"
              placeholder="Filtrar"
              onChange={onchangefilter}
            />
          </Col>
        </FormGroup>{" "} */}
      </div>
      <ReactDataTable
        data={stateAuxiliar}
        columns={columns}
        title="Listado de usuarios "
      />
      <ModalDefault
        size="md"
        modal={modalRoles}
        bandera="roles"
        closeAction={closeActionModal}
        titulo="Roles de usuario"
        icontitulo={<RolesIcon width={30} className="mx-2 " fill="blue" />}
      ></ModalDefault>
      <ModalDefault
        size="md"
        modal={modalClave}
        bandera="clave"
        closeAction={closeActionModal}
        titulo="Clave de usuario"
        icontitulo={<RolesIcon width={30} className="mx-2 " fill="blue" />}
      ></ModalDefault>
      <ModalDefault
        size="md"
        modal={modalModuloCrud}
        bandera="clave"
        closeAction={closeActionModal}
        titulo="crearUsuario"
        icontitulo={<RolesIcon width={30} className="mx-2 " fill="blue" />}
      >
        <ModulosCrud></ModulosCrud>
      </ModalDefault>
    </div>
  );
};

export default ModulosTableComponente;
