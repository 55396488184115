import React, { useContext, useState, useEffect, useRef } from "react";
import AuthContext from "../../Context/auth/authContext";
import Logo from "../../assets/logo.png";
import { ReactComponent as ArrobaIcon } from "../../icons/mail.svg";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";
import { authenticationfn } from "../../api/auth";

const Login = () => {
  const ref = useRef([]);

  let authContext = useContext(AuthContext);
  const [state, setstate] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    ref.current["username"].focus();
  }, []);

  const onChange = (e) => {
    if (e.target.name === "username")
      setstate({
        ...state,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    else
      setstate({
        ...state,
        [e.target.name]: e.target.value,
      });
  };

  const logear = async(e) => {
   const respuesta= await authenticationfn(state);
       console.log("========================");

   console.log(respuesta)
   if(respuesta.sms==="ok"){
     let token = respuesta.token;
     localStorage.setItem("accesTokenAdm", token);
     localStorage.setItem("accesuser_id", respuesta.usuario_id);
     window.location="/"
   }else{
    alert("usuario incorrecto")
   }
  };

  const detectarBotonPresionado = (e) => {
    if (e.key === "Enter") {
      if (e.target.name === "username") ref.current["password"].focus();
      else if (e.target.name === "password") logear();
    }
  };
  return (
    <div
      className=" d-flex justify-content-center align-items-center flex-column bgfondo  "
      style={{ height: "100vh" }}
    >
      {/*begin::Content body*/}
      {/*begin::Signin*/}
      <div className=" bg-white borde-tarjeta card h-auto px-3 py-2 tarjetalogin">
        {/*begin::Form*/}
        <form className="form card-body " id="">
          {/*begin::Title*/}
          <div className="pb-13 pt-lg-0 py-2 text-center">
            <img src={Logo} alt="" width={250} height={250} />
          </div>
          {/*begin::Title*/}
          {/*begin::Form group*/}
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center align-items-center inputIcon">
                <ArrobaIcon />
              </div>
              <input
                className="inputtext form-control w-80"
                type="text"
                placeholder="Digite su correo"
                name="username"
                ref={(r) => (ref.current["username"] = r)}
                value={state.username}
                onChange={onChange}
                onKeyPress={detectarBotonPresionado}
              />
            </div>
          </div>
          {/*end::Form group*/}
          {/*begin::Form group*/}
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center align-items-center inputIcon">
                <LockIcon />
              </div>
              <input
                className="inputtext form-control"
                type="password"
                name="password"
                ref={(r) => (ref.current["password"] = r)}
                placeholder="Digite su contraseña"
                value={state.password}
                onChange={onChange}
                onKeyPress={detectarBotonPresionado}
              />
            </div>
          </div>
          {/*end::Form group*/}
          {/*begin::Action*/}
          <div className="">
            <button
              type="button"
              onClick={logear}
              className="btn btn-primary-vet w-100 p-3 my-3 borde-tarjeta"
            >
              <label
                className="text-btn"
                style={{ color: "#4d4d4d", fontWeight: "bold" }}
              >
                Iniciar sesión
              </label>
            </button>
          </div>
          {/*end::Action*/}
        </form>
        {/*end::Form*/}
      </div>
      {/*end::Signin*/}
    </div>
  );
};
export default Login;
