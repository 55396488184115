export const filtrarUsuario = (array, dato) => {
  var listado = [];
  listado = array;
  var resutadobusqueda = listado.filter((elemento) => {
    if (
      elemento.cedula
        .toString()
        .toLowerCase()
        .includes(dato.toString().toLowerCase()) ||
      elemento.nombres
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(dato.toString().toLowerCase())
    ) {
      return elemento;
    }
  });
  return resutadobusqueda;
};
