
const Dashboard = () => {
  return (
    <div className="d-flex justify-content-start alig-items-center flex-column h-100">
      dashboard
    </div>
  );
};

export default Dashboard;
