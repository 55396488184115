import axios from "axios";

export const getIpLocation = async () => {
  try {
    const respuesta = await axios.get("https://ipapi.co/json/");
    return [respuesta.data];
  } catch (e) {
    console.log(e);
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};
