import React,{useState} from "react";
import VeterinariaTableComponente from "../components/veterinarias/veterinarria.table.componente";
import CrearVeterinarias from "../components/veterinarias/veterinarias.crear.componentes"
import ModalDefault from "../components/Modal/modalDefault";
import {Link} from "react-router-dom"
const Veterinarias = () => {
  const [modalCrearVeterinaria,setModalCrearVeterinaria]=useState(false)

  const closeActionModal=(data,bandera)=>{
    setModalCrearVeterinaria(data)
  }
  return (
    <div className="d-flex justify-content-start alig-items-center flex-column h-100 mx-2">
      <div className="row py-2">
        <label for="" className="titulosubmenusize">
          Empresas panel
        </label>
      </div>
      <div className="row bordecircularpanel contenidopanel bgfondodark p-2">
        <div className="col-md-12">
          <div className="row py-3">
            <Link
              to="/veterinarias/crear"
              className=" d-flex justify-content-center align-items-center bg-btn-vet-green" 
              style={{width:"200px"}}
            >
              Nueva empresa
            </Link>
          </div>
          <div className="row py-3 w-100">
            <VeterinariaTableComponente />
          </div>
        </div>
      </div>
      <ModalDefault
        size="lg"
        modal={modalCrearVeterinaria}
        bandera="clave"
        closeAction={closeActionModal}
        titulo="Crear veterinaria"
        icontitulo={null}
      >
        <CrearVeterinarias></CrearVeterinarias>
      </ModalDefault>
    </div>
  );
};

export default Veterinarias;
