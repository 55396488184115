import Axios from "../config/Axios";
import TokenAuth from "../config/Token";

export const crearUsuario = async (data) => {
  try {
    if (
      data.cedula !== "" &&
      data.nombres !== "" &&
      data.correo !== "" &&
      data.telefono !== "" &&
      data.genero !== ""
    ) {
      TokenAuth(localStorage.getItem("accesTokenCmm"));
      const respuesta = await Axios.post("/createUserAccount", data);
      return [
        {
          data: respuesta.data.data,
          sms: respuesta.data.sms,
          mensaje: respuesta.data.mensaje,
        },
      ];
    } else if (data.cedula === "") {
      return [
        {
          data: [],
          sms: "err",
          mensaje: "El campo cédula es obligatorio",
        },
      ];
    } else if (data.nombres === "") {
      return [
        {
          data: [],
          sms: "err",
          mensaje: "El campo nombre es oligatorio !",
        },
      ];
    } else if (data.telefono === "") {
      return [
        {
          data: [],
          sms: "err",
          mensaje: "El número de telefono es oligatorio !",
        },
      ];
    } else if (data.correo === "") {
      return [
        {
          data: [],
          sms: "err",
          mensaje: "El número de correo es oligatorio !",
        },
      ];
    } else if (data.genero === "") {
      return [
        {
          data: [],
          sms: "err",
          mensaje: "El número de género es oligatorio !",
        },
      ];
    }
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const allUsuarios = async () => {
  try {
    TokenAuth(localStorage.getItem("accesTokenCmm"));
    const respuesta = await Axios.get("/allUsuarios");
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const perfilData = async (usuario) => {
  try {
    TokenAuth(localStorage.getItem("accesTokenCmm"));
    const respuesta = await Axios.get("/perfilData/" + usuario);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const updateUsuarios = async (data) => {
  try {
    TokenAuth(localStorage.getItem("accesTokenCmm"));
    const respuesta = await Axios.post("/updateusuarios/", data);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};

export const updateClave = async (data) => {
  try {
    TokenAuth(localStorage.getItem("accesTokenCmm"));
    const respuesta = await Axios.post("/updateClave/", data);
    return [respuesta.data];
  } catch (e) {
    return [
      {
        data: [],
        sms: "err",
        mensaje: e,
      },
    ];
  }
};



export const BuscarUsers = async (data) => {   
    try {
      const respuesta = await Axios.get("/filtarPersona/" + data);
      return [respuesta.data]
    } catch (e) {
      return [
        {
          sms:"err",
          mensaje:e,
          data:[]
        }
      ]
    }
  
};