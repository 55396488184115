import React, { useEffect, useState } from "react";
/* import ReactDataTable from "../ReactDataTable";
 */import { allModulosDeVeterinarias } from "../../api/modulos";
import { ReactComponent as RolesIcon } from "../../svg/roles.svg";
import ModalDefault from "../Modal/modalDefault";
import {useParams} from "react-router-dom"
import ReactDataTable from "../ReactDataTable"
const VeterinariasDetalle = () => {
  const {veterinaria_id}=useParams()
  const [filtro, setFiltro] = useState("");
  const [state, setState] = useState([]);
  const [stateAuxiliar, setStateAuxiliar] = useState([]);
  const [modalRoles, setModalRoles] = useState(false);
  const [modalClave, setModalClave] = useState(false);
  const [menu, setMenu] = useState("menu-oculto");

  const [selectData, setSelectData] = useState({});

  const columns = [
    {
      name: "MÓDULOS",
      selector: (row) => row.modulos.modulo,
      sortable: false,
    },

    {
      name: "LINK",
      selector: (row) => row.modulos.link,
      sortable: false,
    },
    {
      name: "ROUTE",
      selector: (row) => row.modulos.route,
      sortable: false,
    },
    {
      name: "ORDEN",
      selector: (row) => row.orden,
      sortable: false,
    },
    {
      name: "ACTIVO",
      selector: (row) => row.activo?"true":"false",
      sortable: false,
    },

    {
      name: "OPCIONES",
      sortable: false,
      cell: (row) => opciones(row),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];

   const opciones = (row) => {
     return (
       <div id="menuT">
         <button
           type=""
           className="btn btn-primary btn-sm m-1"
           onClick={() => {
           }}
         >
           usuarios
         </button>
       </div>
     );
   };

  useEffect(() => {
    
    initData();
  }, []);

  const initData = async () => {
    const res = await allModulosDeVeterinarias(veterinaria_id);
    console.log(res);

    setState(res[0].data);
    setStateAuxiliar(res[0].data);
  };

  const closeActionModal = (data, bandera) => {
    if (bandera === "clave") {
      setModalClave(data);
      setSelectData({});
    }
    if (bandera === "roles") {
      setModalRoles(data);
      setSelectData({});
    }
  };



  return (
    <div className="">
      hola
      <ReactDataTable
        data={stateAuxiliar}
        columns={columns}
        title="Listado de usuarios "
      />
      <ModalDefault
        size="md"
        modal={modalClave}
        bandera="clave"
        closeAction={closeActionModal}
        titulo="Clave de usuario"
        icontitulo={<RolesIcon width={30} className="mx-2 " fill="blue" />}
      ></ModalDefault>
    </div>
  );
};

export default VeterinariasDetalle;
